body.contentmanager-contenttype-homepage {
    .homepage {
        .bestsellers {
            .bestsellers-row {
                padding-left: 2.8rem;
                width: 100%;
                .product-item-small {
                    margin-right: 21px;
                    .product-item-info {
                        .product.details {
                            > * {
                                margin: 0px 15px;
                            }
                        }
                        .product-item-inner-long {
                            width: calc((100% - 148px) / 2);
                            text-align: center;
                            .action-primary {
                                margin: 18px 0px;
                                padding: 15px 18px;
                                font-size: 15px;
                                width: 165px;
                            }
                        }
                    }
                }
            }
        }
        .availablenow {
            .availablenow-row {
                width: 100%;
                .product-item-small {
                    margin-right: 21px;
                    .product-item-info {
                        .product.details {
                            > * {
                                margin: 0px 15px;
                            }
                        }
                        .product-item-inner-long {
                            width: calc((100% - 148px) / 2);
                            text-align: center;
                            .action-primary {
                                margin: 18px 0px;
                                padding: 15px 18px;
                                font-size: 15px;
                                width: 165px;
                            }
                        }
                    }
                }
            }
        }
    }
}
