.amgdprcookie-groups-modal {
    .modal-inner-wrap {
        .modal-component {
            .amgdprcookie-main-wrapper {
                .amgdprcookie-settings-form {
                    .amgdprcookie-form-container {
                        .amgdprcookie-header {
                            .amgdprcookie-toggle-cookie {
                                padding-left: initial;
                            }
                        }
                    }
                }
            }
        }
    }
}