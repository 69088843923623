// Minicart
.block-minicart {
    max-width: 100%;
    .block-content .minicart-items-wrapper {
        @media (min-width: 611px) {
            .minicart-items {
                display: flex;
                flex-flow: wrap;
                gap: 21px;
                & > li {
                    width: calc((100% - 21px) / 2);
                }
                .product-item-small .second-line-product .product-item-pricing .btn-round {
                    padding: 3px;
                }
            }
        }
    }
}
