.coming-soon {
    .coming-soon-image {
        min-height: 100px;
    }

    &-info {
        &-countdown {
            .cm-countdown {
                gap: 0 8px;

                .block-available {
                    flex: 1 0 100%;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
